import React from 'react';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Route from '../Route';

import { store, persistor } from '../store';

import { Container } from '../components/pages/styles';

export default function Password() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Layout>
          <Header />
          <Container>
            <Route />
          </Container>

          <ToastContainer autoClose={3000} />
        </Layout>
      </PersistGate>
    </Provider>
  );
}
